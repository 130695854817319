


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import { HollowDotsSpinner } from "epic-spinners";
import UpdateEmail from "@/views/UpdateEmail.vue";
import AddNewBroker from "@/views/AddNewBroker.vue";
import { ALLOWED_IMAGES_EXTENSIONS } from "@/constants";

@Component({ components: { HollowDotsSpinner, UpdateEmail, AddNewBroker } })
export default class MyAccountListComponent extends Vue {
  public allowedFileExtensions = ALLOWED_IMAGES_EXTENSIONS;
  public invalidPassword: string = null;
  public passwordMatched: string = null;
  public invalidNewPassword = false;
  public inProcess = false;
  public changePasswordObj = {
    currentPassword: "",
    newPassword: "",
    confirmationpassword: ""
  };
  public userType = this.$store.state.sessionObject.type;
  public invitationLink = false;
  public hasMailerPassword = null;
  public mailerPassword = null;
  public smtpAuthEmailAddress = null;
  public wemloProcessor: any = {};
  public reportedTo = "";
  public dotLoader = false;
  public superVisorName = [];
  // public alphaProfile = "";
  // public imageCordinates = {
  //   height: 0,
  //   width: 0
  // };

  public async getWemloProcessor() {
    this.dotLoader = true;
    try {
      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getWemloProcessorList",
        {
          params: { userId: this.$userId }
        }
      );
      this.wemloProcessor = response.data.data;
      this.smtpAuthEmailAddress = this.wemloProcessor.smtpAuthEmailAddress;
      if (this.wemloProcessor.hasOwnProperty("mailerPassword")) {
        this.hasMailerPassword = true;
      }
      this.superVisorName.push(response.data.supervisorName);
      this.dotLoader = false;
    } catch (error) {
      this.dotLoader = false;
      console.log(error);
    }
  }
  public async changePassword() {
    try {
      let formValid = await this.$validator.validateAll("pass");
      if (!formValid) return;
      this.inProcess = true;
      this.changePasswordObj["userId"] = this.$userId;

      let response = await Axios.post(
        BASE_API_URL + "authentication/verifyAndChangePassoword",
        this.changePasswordObj);
      if (response.data.data == "Success") {
        this.$snotify.success("Password has been successfully changed.");
        this.$router.push("/broker/dashboard");
      } else if (response.data.data == "InvalidPassword") {
        this.invalidPassword = "Your current password is incorrect.";
      } else if (response.data.data == "passwordMatched") {
        this.passwordMatched =
          "New password cannot be the same as last five passwords.";
      } else if (response.data.data == "invalidNewPassword") {
        this.invalidNewPassword = true;
      }
      this.inProcess = false;
    } catch (error) {}
  }
  /**************************************************************************************************
   *                                 FOR SAVING PROCESSSORS MAILER PASSWORD                          *
   **************************************************************************************************/
  public async updateMailerPassword(check) {
    //this.$store.state.wemloLoader = true;
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) return;
      this.$store.state.wemloLoader = true;
      let data = {
        password: this.mailerPassword,
        userId: this.$userId,
        smtpAuthEmailAddress: this.smtpAuthEmailAddress
      };
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/updateMailerPassword",
        data);
      if (response.data.status == 200) {
        this.getWemloProcessor();
        this.$snotify.success(response.data.message);
      } else {
        this.$snotify.error(response.data.message);
      }
      this.mailerPassword = null;
      this.smtpAuthEmailAddress = null;

      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  // get profilePicture() {
  //   if (this.$store.state.sessionObject.userInfo.profilePicture) {
  //     let arr = this.$store.state.sessionObject.userInfo.profilePicture.split(
  //       "."
  //     );
  //     let ext = arr.pop();
  //     let src = arr[0].substring(0, 58);
  //     this.setCordinatesOfImage(src + "small." + ext);
  //     return src + "small." + ext;
  //   } else {
  //     if (this.$store.state.sessionObject.userInfo.profilePicture == null) {
  //       this.alphaProfile = this.wemloProcessor.firstName
  //         .slice(0, 1)
  //         .toUpperCase();
  //     }
  //     return null;
  //   }
  // }

  // public setCordinatesOfImage(url) {
  //   var image = new Image();
  //   image.src = url;
  //   image.onload = () => {
  //     if (image.height >= image.width) {
  //       this.imageCordinates.width = image.width / 3;
  //       this.imageCordinates.height = image.height / 3;
  //     } else {
  //       this.imageCordinates.width = image.width / 2;
  //       this.imageCordinates.height = image.height / 2;
  //     }
  //   };
  // }
  public async sendInvitationToBroker() {
    this.invitationLink = true;
  }
  public async uploadFile(e) {
    this.$store.state.wemloLoader = true;
    try {
      var files = e.target.files;
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("userType", this.$store.state.sessionObject.type);
      let response = await Axios.post(
        BASE_API_URL + "broker/uploadProfilePicture",
        formData);
      // setTimeout(e => {
      this.$store.dispatch("setProfilePicture", { path: response.data.profilePicture, data: response.data.data });
      this.$store.state.wemloLoader = false;
      this.$snotify.success("Profile picture updated successfully");
      this.$store.state.wemloLoader = false;
      // this.profilePicture;
      // location.reload();
      //   this.$router.go(0);
      // }, 3000);
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error.response, "error");
    }
  }

  public isWemloProcessor(email){
    return !(email && email.split('@')[1] != 'wemlo.io' && email.split('@')[1] != 'remaxtest.net');
  }

  mounted() {
    this.getWemloProcessor();
  }
}
