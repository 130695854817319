


import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import Axios from "axios";
import { BASE_API_URL } from "@/config";


@Component
export default class JuniorLeaveRequests extends mixins(CommonMixin) {
  public juniorsLeaveRequests = [];
  public async getJuniorsLeaveRequests() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getJuniorsLeaveRequests/" + this.$userId);
      response.data.juniorLeaveRequests.forEach(element => {
          element['fullName'] = element.lastName? element.firstName+" "+element.lastName:element.firstName; 
      });
      this.juniorsLeaveRequests = response.data.juniorLeaveRequests;
    } catch (error) {
      console.log(error.response);
    }
  }

  public async updateLeaveStatus(index){
      try {
          let response = await Axios.post(
              BASE_API_URL + "wemloprocessor/updateLeaveStatus/",this.juniorsLeaveRequests[index])
          this.$snotify.success("Holiday ",this.juniorsLeaveRequests[index].status)  
      } catch (error) {
          console.log(error.response);          
      }
  }

  public getDateInFormat(date) {
    let x = new Date(date);
    let d = x.getDate() > 9 ? x.getDate() : "0" + x.getDate();
    let m = x.getMonth() > 9 ? x.getMonth() : "0" + x.getMonth();
    let y = x.getFullYear() > 9 ? x.getFullYear() : "0" + x.getFullYear();
    return d + "-" + m + "-" + y;
  }

  async mounted() {
    await this.getJuniorsLeaveRequests();
  }
}
