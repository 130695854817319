


import { Component, Vue } from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import { VueEditor } from "vue2-editor";
@Component({
  components: {
    Datepicker,VueEditor
  }
})
export default class Leave extends Vue {
  public leaveRequestsList: any = {};
  public leaveRequest = {
    leaveType: null,
    fromDate: new Date(),
    toDate: new Date(),
    reason: null
  };
  public leaveData: any = {};
  public dateSame =false;
  public state:any={};
  public juniorLeaveRequest:any={};
  public viewJuniorRequestShow =false;

  get reportTo() {
    return this.$store.state.sessionObject.userInfo.reportTo;
  }

  public async requestForLeave() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        return;
      } 
      this.leaveData["reportTo"] = this.reportTo;
      this.leaveData["status"] = "Requested";
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/saveLeaveRequest",
        {
          data: this.leaveData,
          userId: this.$userId
        });
      this.leaveRequestsList.unshift(this.leaveData);
      this.leaveData = this.leaveRequest = {
          leaveType: null,
          fromDate: new Date(),
          toDate: new Date(),
          reason: null
      };
      this.$modal.hide("requestLeaveModel");
      this.$snotify.success("Leave request submitted successfully.");
    } catch (error) {
      console.log(error);
    }
  }

  public async getOwnLeaveRequests(){
    try {
      let response =await Axios.get(
        BASE_API_URL+"wemloprocessor/getOwnLeaveRequests/"+this.$userId);
      this.leaveRequestsList = response.data.leaveRequests;       
    } catch (error) {
      console.log(error.response);      
    }
  }

  public getDateInFormat(date){
    let x = new Date(date);
    let d = x.getDate()>9?x.getDate():"0"+x.getDate(); 
    let m = x.getMonth()>9?x.getMonth():"0"+x.getMonth();
    let y = x.getFullYear()>9?x.getFullYear():"0"+x.getFullYear();
    return d+"-"+m+"-"+y;
  }

  public closeRequestLeaveModel(){
    this.leaveData = this.leaveRequest = {
          leaveType: null,
          fromDate: new Date(),
          toDate: new Date(),
          reason: null
      };
    this.$modal.hide("requestLeaveModel");
  }

  public requestALeave(){
    this.leaveData = this.leaveRequest;
    this.$modal.show("requestLeaveModel");
  }
  public sameDate(){
    this.dateSame=false;
  }
  public disablingToDate(){
  let fromDate = new Date(this.leaveData.fromDate)
  // this.state.disabledDatesTo.from = this.leaveData.fromDate;
    this.state.disabledDatesTo.to = fromDate;
   this.leaveData.toDate  = new Date(fromDate.setDate(fromDate.getDate()+1));
      }
  async mounted() {
    await this.getOwnLeaveRequests();
    let date = new Date().setDate(new Date().getDate()-1);
      this.state = {
        disabledDatesFrom: {
          to: new Date(date)
        },
        disabledDatesTo : {
          to : new Date(date)
        }
      }
    this.juniorLeaveRequest = await Axios.get(
        BASE_API_URL + "wemloprocessor/getJuniorsLeaveRequests/" + this.$userId);
     if(this.juniorLeaveRequest.data.status == true){
    this.viewJuniorRequestShow =true;
     }else {
       this.viewJuniorRequestShow =false;
     }
    }
}
